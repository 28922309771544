<template>
  <v-form v-if="item" style="overflow: auto">
    <v-container fluid>
      <v-subheader>
        Личные данные
      </v-subheader>
      <v-divider></v-divider>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="item.person.fullName"
            label="ФИО"
            readonly
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.person.iin"
            label="ИИН"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.person.birthDate"
            label="Дата рождения"
            type="date"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="item.person.sexKey"
            :items="ctx.src.dict['INS_SEX'].items"
            :label="'Пол'"
            item-text="value"
            item-value="key"
            readonly
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.person.phoneMobile1"
            label="Мобильный телефон 1"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.person.phoneMobile2"
            label="Мобильный телефон 2"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.person.phoneHome"
            label="Домашний телефон"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.person.phoneWork"
            label="Рабочий телефон"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="item.person.cityKey"
            :items="ctx.src.dict['INS_CITY'].items"
            :label="'Город'"
            item-text="value"
            item-value="key"
            readonly
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="9">
          <v-text-field
            v-model="item.person.address"
            label="Адрес"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-subheader>
        Данные страховки
      </v-subheader>
      <v-divider></v-divider>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-autocomplete
            v-model="item.contractId"
            :items="ctx.src.contracts.items"
            :label="'Договор страхования'"
            item-text="number"
            item-value="id"
            readonly
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.number"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <h6>Компания</h6>
                        <h4>
                          {{
                            ctx.src.org.first(data.item.organizationId).fullName
                          }}
                        </h4>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <h6>Страховая</h6>
                        <h4>
                          {{
                            ctx.src.org.first(data.item.underwriterId).fullName
                          }}
                        </h4>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-autocomplete
            v-model="item.programId"
            :items="programs"
            :label="'Программа'"
            item-text="name"
            item-value="id"
            readonly
          >
            <template v-slot:item="data">
              <template v-if="!data.item.id">
                <v-list-item-content
                  v-text="'Загрузка...'"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <h6>Страховая сумма</h6>
                        <h4>{{ data.item.amount }}</h4>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h6>Страховая премия</h6>
                        <h4>{{ data.item.premium }}</h4>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.cardNo"
            label="Номер карты"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-autocomplete
            v-model="item.parentId"
            :items="ctx.src.insured.items"
            :label="'Прикреплен к'"
            item-text="label"
            item-value="id"
            readonly
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.individualContractNo"
            label="Инд. № договора"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="organization"
            label="Компания"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="underwriter"
            label="Страховая компания"
            readonly
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.startDate"
            label="Дата с"
            type="date"
            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.endDate"
            label="Дата по"
            type="date"
            readonly
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.amount"
            label="Сумма"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.premium"
            label="Премия"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import vm2js from "../../utils/vm2js";
import dateIsoFilter from "../../filters/dateIso.filter";

let Item = function(d) {
  let self = this;

  d.person = d.personId;

  self.person2vm = function(val) {
    return ctx.src.persons.get(val);
  };
  self.startDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.endDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  js2vm(self, d);
};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    item: undefined
  }),
  watch: {
    "vm.item.insuredId": async function(val) {
      this.item = undefined;
      if (!val) return;
      this.fetchItem(val);
    }
  },
  computed: {
    contract: function() {
      return ctx.src.contracts.get(this.item.contractId);
    },
    organization: function() {
      if (!this.contract) return undefined;
      return ctx.src.org.first(this.contract.organizationId).fullName;
    },
    underwriter: function() {
      if (!this.contract) return undefined;
      return ctx.src.org.first(this.contract.underwriterId).fullName;
    },
    programs: function() {
      return ctx.src.programs.byContract(this.item.contractId);
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async fetchItem(id) {
      if (!id) return;
      let res = await fetch.get("/api/insurance/insured/get?id=" + id);
      if (res) this.item = new Item(vm2js(res));
    }
  },
  mounted() {
    this.vm.insured = this;
    this.fetchItem(this.vm.item.insuredId);
  }
};
</script>
