<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>filter_alt</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Фильтр</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.from"
                      label="С"
                      type="date"
                      required
                      @keydown.enter="fetchItems"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.to"
                      label="По"
                      type="date"
                      required
                      @keydown.enter="fetchItems"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-autocomplete
                      v-model="filter.insuredId"
                      :items="ctx.src.insured.items"
                      :label="'Застрахованный'"
                      item-text="label"
                      item-value="id"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn text @click="resetFilter">
                Сбросить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="fetchItems">
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-dialog
          v-if="rightFilter('d')"
          v-model="dialogDelete"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="!item || !item.id" v-bind="attrs" v-on="on">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить визит?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="rem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        :item-class="itemColor"
        @click:row="rowClick"
      >
        <template v-slot:item.visitDate4List="{ item }">
          <span>{{ item.visitDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.actionDate4List="{ item }">
          <span>{{ item.actionDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.insured.startDate="{ item }">
          <span>{{
            item.insured ? item.insured.startDate : undefined | dateLocalFilter
          }}</span>
        </template>
        <template v-slot:item.insured.endDate="{ item }">
          <span>{{
            item.insured ? item.insured.endDate : undefined | dateLocalFilter
          }}</span>
        </template>
      </v-data-table>
    </div>
    <div v-if="item" class="view">
      <v-tabs class="toolbar">
        <v-tab>Детали</v-tab>
        <v-tab v-if="item.insuredId">Застрахованный</v-tab>
        <v-tab v-if="item.insuredId">Баланс</v-tab>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <div
            class="view"
            style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden"
          >
            <v-toolbar class="my-bar">
<!--              <v-btn v-if="rightFilter('w')" icon @click="save">
                <v-icon>save</v-icon>
              </v-btn>-->
              <SaveButton
              :handler="save"
              >

              </SaveButton>
              <v-spacer></v-spacer>
            </v-toolbar>
            <Detail :vm="this"></Detail>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Insured :vm="this"></Insured>
        </v-tab-item>
        <v-tab-item>
          <Balance :insuredId="item.insuredId"></Balance>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import Detail from "../components/visits/Detail";
import dateIsoFilter from "../filters/dateIso.filter";
import dictFilter from "../filters/dict.filter";
import js2vm from "../utils/js2vm";
import ctx from "@/ctx";
import Insured from "../components/visits/Insured";
import vm2js from "../utils/vm2js";
import moment from "moment";
import rightFilter from "../filters/right.filter";
import Balance from "../components/balance/Master";
import SaveButton from "@/components/ButtonFacade";

let Filter = function() {
  let self = this;

  let from = new Date();
  from.setDate(from.getDate() - 5);
  self.from = dateIsoFilter(from);
  self.to = dateIsoFilter(new Date());

  self.insuredId = undefined;
};

let Item = function(d) {
  let self = this;

  d.status = d.statusKey;
  d.organization = d.organizationId;
  d.doctor = d.doctorId;
  d.type = d.typeKey;
  d.insured = d.insuredId;

  d.visitDate = !d.visitDate ? new Date() : d.visitDate;
  d.visitTime = !d.visitTime ? moment().format("HH:mm") : d.visitTime;
  d.actionDate = !d.actionDate ? new Date() : d.actionDate;

  self.insured2vm = function(val) {
    if (!val) return {};
    return ctx.src.insured.get(val);
  };
  self.organization2vm = function(val) {
    if (!val) return {};
    return ctx.src.org.first(val);
  };
  self.doctor2vm = function(val) {
    if (!val) return {};
    return ctx.src.doctors.get(val);
  };
  self.visitDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.actionDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.status2vm = function(val) {
    return dictFilter("INS_VISIT_STATUS", val);
  };
  self.type2vm = function(val) {
    return dictFilter("INS_VISIT_TYPE", val);
  };

  self.init = function(d) {
    js2vm(self, d);
  };

  self.init(d);
};

export default {
  data: () => ({
    ctx,
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    selected: [],
    loading: false,
    headers: [
      {
        text: "",
        align: "middle",
        sortable: false
      },
      {
        text: "Статус",
        align: "start",
        sortable: true,
        value: "status"
      },
      { text: "Дата создания", sortable: true, value: "visitDate4List" },
      { text: "Время создания", sortable: false, value: "visitTime" },
      { text: "Тип", sortable: true, value: "type" },
      { text: "Поставщик", sortable: true, value: "organization.fullName" },
      { text: "Врач", sortable: false, value: "doctor.fullName" },
      { text: "Дата актива", sortable: true, value: "actionDate4List" },
      { text: "Время актива", sortable: false, value: "actionTime" },
      { text: "№ карты", sortable: true, value: "insured.cardNo" },
      { text: "ФИО", sortable: true, value: "insured.fullName" },
      { text: "Контакты", sortable: false, value: "contacts" },
      { text: "Действителен с", sortable: false, value: "insured.startDate" },
      { text: "Действителен по", sortable: false, value: "insured.endDate" },
      { text: "Создал", sortable: false, value: "creatorName" }
    ],
    items: [],
    item: undefined,
    filter: new Filter(),
    dialog: false,
    dialogDelete: false
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = new Item(vm2js(val[0]));
    }
  },
  methods: {
    itemColor(item) {
      if (moment(item.actionDate, "YYYY-MM-DD") > moment()) return "item-blue";
      return "";
    },
    resetFilter() {
      this.filter = new Filter();
    },
    add() {
      this.close();
      this.selected.push(new Item({}));
    },
    async save() {
      const res = await fetch.post(
        "/api/medcases/visits/save",
        vm2js(this.item)
      );
      if (res) {
        if (this.item.id) {
          let found = this.items.find(i => {
            return i.id === res;
          });
          if (found) Object.assign(found, this.item);
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        this.close();
      }
    },
    async rem() {
      const res = await fetch.post(
        "/api/medcases/visits/delete?id=" + this.item.id
      );
      if (res) {
        this.close();
        this.items.splice(this.item, 1);
        this.dialogDelete = false;
      }
    },
    close() {
      this.selected = [];
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems() {
      this.loading = true;
      this.selected = [];
      this.items = [];
      this.dialog = false;
      let res = await fetch.post(
        "/api/medcases/visits/get-list",
        vm2js(this.filter)
      );
      if (res) {
        res.forEach(element => {
          this.items.push(new Item(element));
        });
      }
      this.loading = false;
    }
  },
  mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  },
  components: {
    SaveButton,
    Detail,
    Insured,
    Balance
  }
};
</script>
